import { useSelector, useDispatch } from "react-redux";
import React, { useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import { SET_LANGUAGE, TOGGLE_THEME } from "./store/actions";
// routing
import Routes from "./routes";

// defaultTheme
import themes from "./themes";

// project imports
import NavigationScroll from "./layout/NavigationScroll";
import { useLocation } from "react-router-dom";

// ==============================|| APP ||============================== //
const options = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: "30px",
  // you can also just use 'scale'
  transition: transitions.SCALE,
  containerStyle: {
    zIndex: 9999,
  },
};
const App = () => {
  const dispatch = useDispatch();
  const customization = useSelector((state) => state.customization);
  const location = useLocation();
  //console.log(customization);
  useEffect(() => {
    let language = localStorage.getItem("language");
    let theme = localStorage.getItem("theme");
    if (language == null) {
      localStorage.setItem("language", "cs");
      dispatch({ type: SET_LANGUAGE, language: "cs" });
    } else {
      localStorage.setItem("language", "cs");
      dispatch({ type: SET_LANGUAGE, language: "cs" /*language*/ });
    }
    if (!theme) {
      localStorage.setItem("theme", "light");
    } else if (theme === "dark") {
      dispatch({ type: TOGGLE_THEME });
    }
  }, [dispatch]);

  useEffect(() => {
    localStorage.setItem("theme", customization.theme);
  }, [customization.theme]);

  return (
    <React.StrictMode>
      <AlertProvider template={AlertTemplate} {...options}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={themes(customization)}>
            <CssBaseline />
            <NavigationScroll>
              <Routes />
            </NavigationScroll>
          </ThemeProvider>
        </StyledEngineProvider>
      </AlertProvider>
    </React.StrictMode>
  );
};

export default App;
