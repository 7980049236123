import accounts from "./accounts";
import main from "./main.js";

import Auth from "./../utils/auth.js";
import { useLocation, useNavigate } from "react-router-dom";

// ==============================|| MENU ITEMS ||============================== //

const useMenuItems = (appState, notificationSegment) => {
  const navigate = useNavigate();
  const location = useLocation();

  let idSubject = Auth.getSubject();

  const currentPath = location.pathname;

  let menuItems = {
    items: [main(navigate, appState, notificationSegment), accounts(navigate)],
  };

  if (!currentPath.startsWith("/app-sections")) {
    menuItems = {
      items: [accounts(navigate, idSubject)],
    };
  } else if (appState) {
    menuItems = {
      items: [main(navigate, appState, notificationSegment)],
    };
  } else {
    menuItems = {
      items: [accounts(navigate, idSubject)],
    };
  }

  return menuItems;
};

export default useMenuItems;
