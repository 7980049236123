import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

// material-ui
import { styled, useTheme } from "@mui/material/styles";
import { Box, CssBaseline, useMediaQuery } from "@mui/material";

// project imports
import Sidebar from "./Sidebar";
import { drawerWidth } from "./../../store/constant";
import { SET_MENU } from "./../../store/actions";

// assets
import Auth from "./../../utils/auth.js";
import SmartHeader from "./SmartHeader";
import { css, Global } from "@emotion/react";
import MobileHeader from "./MobileHeader/MobileHeader.js";
// styles
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: open ? 0 : `-${drawerWidth}px`,
    width: open ? `calc(100% - ${drawerWidth}px)` : "100%",
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    [theme.breakpoints.down("md")]: {
      marginLeft: open ? "20px" : "0px",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: open ? "10px" : "0px",
    },
  })
);

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const matchUpSm = useMediaQuery(theme.breakpoints.up("sm"));
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  // Handle left drawer
  const leftDrawerOpened = useSelector((state) => state.customization.opened);

  const dispatch = useDispatch();
  const handleLeftDrawerToggle = () => {
    dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
  };

  useEffect(() => {
    dispatch({ type: SET_MENU, opened: matchUpSm });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchUpSm]);
  function renderRouting() {
    if (Auth.isAuth()) {
      return <Outlet />;
    } else {
      return <Navigate to="/login" replace />;
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        backgroundColor: theme.palette.background.default,
      }}
    >
      <CssBaseline />
      {/* drawer */}
      <Sidebar
        drawerOpen={leftDrawerOpened}
        drawerToggle={handleLeftDrawerToggle}
      />
      {/* main content */}
      <Main
        theme={theme}
        open={leftDrawerOpened}
        sx={{
          width: leftDrawerOpened ? `calc(100% - ${drawerWidth}px)` : "100%",
        }}
      >
        <Global
          styles={css`
            .ColorPicker-MuiBox-root {
              background-color: ${theme.palette.background.paper} !important;
            }
            .ColorPicker-MuiInputBase-root {
              color: ${theme.palette.text.default} !important;
            }
            .ColorPicker-MuiTypography-root {
              color: ${theme.palette.text.default} !important;
            }
            .ColorPicker-MuiFormLabel-root {
              color: ${theme.palette.text.default} !important;
            }
            .ColorPicker-MuiButton-root {
              box-shadow: 0px 0px 10px grey !important;
            }
          `}
        />
        {isMobile ? (
          !leftDrawerOpened && (
            <MobileHeader
              drawerOpen={leftDrawerOpened}
              drawerToggle={handleLeftDrawerToggle}
            />
          )
        ) : (
          <SmartHeader
            drawerOpen={leftDrawerOpened}
            drawerToggle={handleLeftDrawerToggle}
          />
        )}
        {renderRouting()}
      </Main>
    </Box>
  );
};

export default MainLayout;
