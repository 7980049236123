import { useState, useRef, useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import secure from "../../../../utils/secure.js";
import { getApp, getWallet } from "../../../../utils/rest.js";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  CircularProgress,
  ClickAwayListener,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Typography,
} from "@mui/material";

// third-party
import PerfectScrollbar from "react-perfect-scrollbar";

// project imports
import MainCard from "./../../../../ui-component/cards/MainCard";
import Transitions from "./../../../../ui-component/extended/Transitions";

// assets
import { IconLogout, IconSettings, IconWallet } from "@tabler/icons";
import blancImage from "../../../../assets/images/appBlanc.png";

// ==============================|| PROFILE MENU ||============================== //
import Auth from "./../../../../utils/auth.js";

import languagesLabels from "./../../../../languages/languages";
import { SET_CREDIT } from "./../../../../store/actions";
const WalletSection = () => {
  const theme = useTheme();
  const customization = useSelector((state) => state.customization);
  const location = useLocation();
  const navigate = useNavigate();
  const [roleLetter, setRoleLetter] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [open, setOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(
    customization.language
  );

  const [wallet, setWallet] = useState(null);
  const [smsMin, setSmsMin] = useState("0 Kč");
  const [paroMin, setParoMin] = useState("0 Kč");
  const dispatch = useDispatch();
  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef(null);

  useEffect(() => {
    if (isMainPage()) {
      setLoading(false);
    } else if (location?.state?.app?._id) {
      onGetWallet();
      setInterval(() => {
        onGetWallet();
      }, 60000);
    } else {
      setLoading(false);
    }
  }, [location]);

  function isMainPage() {
    return location.pathname === "/";
  }

  function parseCzk(ha) {
    let decimal = Math.floor(ha / 100);
    return decimal + " Kč " + ((ha / 10) % 10) * 10 + " ha";
  }
  function parseCzk2(kc) {
    let decimal = 0;
    if (kc < 0) {
      decimal = Math.ceil(kc);
      return (
        "- " + -1 * decimal + " Kč " + -1 * Math.floor((kc % 1) * 100) + " ha"
      );
    } else {
      decimal = Math.floor(kc);
      return decimal + " Kč " + Math.ceil((kc % 1) * 100) + " ha";
    }
  }
  async function onGetWallet() {
    let token = await Auth.getToken();

    try {
      let encrypted = secure.encrypt({ idApp: location.state.app._id, token });
      let res = await axios.get(getWallet, {
        params: { appsisto: encrypted },
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          "Access-Control-Allow-Credentials": true,
        },
      });

      dispatch({ type: SET_CREDIT, credit: res.data.wallet.credit });
      setWallet(res.data.wallet);

      setSmsMin(parseCzk(res.data.smsMin));
      setParoMin(parseCzk(res.data.paroMin));
    } catch (err) {
      /*alert.show("Něco se porouchalo...", {
        timeout: 2000, // custom timeout just for this one alert
        type: "error"
      });*/
      console.log(err);
    }
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        onClick={handleToggle}
        sx={{ width: "100px", cursor: "pointer" }}
      >
        <Grid item>
          <Grid container alignItems="center" sx={{ marginRight: "5px" }}>
            <Grid item>
              <IconWallet size="1.2rem" />
            </Grid>
            <Grid item>
              <Typography
                ref={anchorRef}
                sx={{ color: theme.palette.text.primary }}
              >
                {parseCzk2(customization.credit)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [30, 30],
              },
            },
          ],
        }}
        style={{ zIndex: 1300 }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  border={false}
                  elevation={160}
                  content={false}
                  boxShadow
                  shadow={theme.shadows[16]}
                >
                  <Box sx={{ p: 2 }}>
                    <div>{"IBAN: " + wallet.IBAN}</div>
                    <div>{"VS: " + wallet.variableSymbol}</div>
                    {location.state.app.useSmsNotifications && (
                      <div>
                        {"Minimální suma pro odeslání oznámení pomocí SMS: " +
                          smsMin}
                      </div>
                    )}
                    {location.state.app.useParo && (
                      <div>
                        {"Minimální suma pro spuštění participačního rozpočtu, pokud je nastavena verifikace pomocí SMS: " +
                          paroMin}
                      </div>
                    )}
                    <div>
                      {
                        "Pokud máte problém s dobitím kreditu, tak kontaktujte naši technickou podporu"
                      }
                    </div>
                  </Box>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default WalletSection;
