// action - customization reducer
export const SET_MENU = "@customization/SET_MENU";
export const MENU_TOGGLE = "@customization/MENU_TOGGLE";
export const MENU_OPEN = "@customization/MENU_OPEN";
export const SET_FONT_FAMILY = "@customization/SET_FONT_FAMILY";
export const SET_BORDER_RADIUS = "@customization/SET_BORDER_RADIUS";
export const SET_LANGUAGE = "@customization/SET_LANGUAGE";
export const TOGGLE_THEME = "@customization/TOGGLE_THEME";
export const CURRENT_PAGE = "@customization/CURRENT_PAGE";
export const SET_CREDIT = "@customization/SET_CREDIT";
